


























































































































































































import { appName, merchantURL, appUrl } from "@/env";
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfile } from "@/interfaces";
import { readUserProfile } from "@/store/main/getters";
import { dispatchAllReceipts } from "@/store/admin/actions";
import _ from "lodash";
import moment, { duration } from "moment-timezone";
import Apply from "../../Apply.vue";
import EditUser from "./EditUser.vue";
import UploadUserDoc from "./UploadUserDoc.vue";
import EditAddress from "./EditAddress.vue";
import AdminViewUser from "./AdminViewUser.vue";
import JsonCSV from "vue-json-csv";
import UploadReceipt from "./UploadReceipt.vue";

@Component({
  components: {
    Apply,
    JsonCSV,
    AdminViewUser,
    EditUser,
    UploadUserDoc,
    EditAddress,
    UploadReceipt,
  },
})
export default class SalesReport extends Vue {
  public dialog = false;

  public onlyAdmin = false;
  public onlyPaid = false;

  public headers() {
    return [
      {
        text: "Payment Date",
        sortable: true,
        value: "receipt.created_date",
        align: "left",
      },
      {
        text: "Actions",
        value: "actions",
        align: "left",
      },
      {
        text: "Upload Receipt",
        value: "upload_receipt",
        align: "left",
      },
      {
        text: "Upload Date",
        value: "receipt.manual_uploaded_date",
        align: "left",
      },
      {
        text: "First Name",
        sortable: true,
        value: "user.first_name",
        align: "left",
      },
      {
        text: "Last Name",
        sortable: true,
        value: "user.last_name",
        align: "left",
      },
      {
        text: "Plan",
        sortable: true,
        value: "receipt.plan_name",
        align: "left",
      },
      {
        text: "Payment Method",
        sortable: true,
        value: "pmethod",
        align: "left",
      },
      {
        text: "Created Date",
        sortable: true,
        value: "receipt.created_date",
        align: "left",
      },
      {
        text: "Month",
        sortable: true,
        value: "receipt.month",
        align: "left",
      },
      {
        text: "Year",
        sortable: true,
        value: "receipt.year",
        align: "left",
      },
      {
        text: "Amount",
        sortable: true,
        value: "receipt.TranAmt",
        align: "left",
      },
      {
        text: "Promo Code",
        sortable: true,
        value: "receipt.promo_code",
        align: "left",
      },
      {
        text: "Promo Cost",
        sortable: true,
        value: "receipt.promo_cost",
        align: "left",
      },
      {
        text: "Splynx CID",
        sortable: true,
        value: "user.splynx_cid",
        align: "left",
      },
      {
        text: "Paynamics Request ID",
        sortable: true,
        value: "receipt.TranID",
        align: "left",
      },
      {
        text: "Billing ID",
        sortable: true,
        value: "receipt.billing_id",
        align: "left",
      },

      {
        text: "Recurring Billing ID",
        sortable: true,
        value: "receipt.rebill_id",
        align: "left",
      },
      {
        text: "Refund ID",
        sortable: true,
        value: "receipt.refund_id",
        align: "left",
      },

      {
        text: "Splynx Payment ID",
        sortable: true,
        value: "receipt.splynx_payment_id",
        align: "left",
      },
      {
        text: "Splynx Invoice ID",
        sortable: true,
        value: "receipt.splynx_invoice_id",
        align: "left",
      },
      {
        text: "Record Updated Date",
        sortable: true,
        value: "receipt.updated_date",
        align: "left",
      },
      {
        text: "Transaction",
        sortable: true,
        value: "receipt.splynx_items_json",
        align: "left",
      },
    ];
  }

  public search = "";
  public search2 = "";
  public all_receipts = [];
  public userCSV = [];
  public promoCSV = [];
  public promos = [];
  public user_id = 0;
  public scroll_item = "";
  public onlyActive = true;
  public userToEdit = {};
  public dialogUploadReceipt = false;
  public receipt_id = "";

  $refs!: Vue["$refs"] & {
    upload_receipt: typeof UploadReceipt;
  };
  toggleOnlyActive() {
    this.onlyActive = !this.onlyActive;
  }
  stringify(item) {
    return JSON.stringify(item);
  }
  created() {
    if (location.host.startsWith("dev")) {
      document.title = "Dev: Building Status Report";
    } else {
      document.title = "Building Status Report";
    }
  }
  public async mounted() {
    const data = await dispatchAllReceipts(this.$store);
    const users = _.get(data, "data.data.users", []);
    const user_map = {};
    const billing_map = {};
    const rebill_map = {};
    users.forEach((user) => {
      user_map[user.id] = user;
    });
    const billings = _.get(data, "data.data.billings", []);
    billings.forEach((billing) => {
      billing_map[billing.id] = billing;
    });
    const rebills = _.get(data, "data.data.rebills", []);
    const token = _.get(data, "data.data.token", "");
    const all_receipts = _.get(data, "data.data.all_receipts", []);
    this.all_receipts = all_receipts.map((receipt) => {
      const user = user_map[receipt.user_id];
      const billing = billing_map[receipt.billing_id];
      const rebill = rebill_map[receipt.rebill_id];
      return {
        token: token,
        billing: billing,
        rebill: rebill,
        user: user,
        receipt: receipt,
      };
    });
    const userFields = this.headers().map((header) => header.value);
    this.userCSV = this.all_receipts.map((user) => {
      const item = {};
      console.log(user);
      this.headers().forEach((header) => {
        item[header.text] = _.get(user, header.value, "");
      });
      return item;
    });
    console.log(data);

    //console.log(this.promos);
    console.log("all_receipts", this.all_receipts);
  }

  diffDates(date1, date2) {
    return moment(date2).diff(moment(date1), "days");
  }
  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm:ss");
    }
    return "";
  }
  formatDateOnly(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY");
    }
    return "";
  }
  async editItem(user_id, scroll_item = null) {
    this.user_id = user_id;
    this.scroll_item = scroll_item;
  }

  getPDFLink(user, type, doc_id) {
    console.log(this.$store.token);
    return `/api/v1/users/pdf/${this.userProfile.id}/${type}/${doc_id}/${user.token}/`;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  getPmethod(item) {
    if (item.billing) {
      return item.billing.ptype;
    }
    if (item.rebill) {
      return item.rebill.ptype;
    }
  }

  public async showUploadReceipt(user, receipt_id) {
    this.userToEdit = user;
    this.receipt_id = `${receipt_id}`;
    this.dialogUploadReceipt = true;
    setTimeout(() => {
      this.$refs.upload_receipt.reset();
    }, 100);
  }

  public async closeUploadReceipt(user_id) {
    this.dialogUploadReceipt = false;
    this.dialog = false;

    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }
}
